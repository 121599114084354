// ========== profile ========== //
.profile {
  padding-top: 1rem;
  order: 0;
  vertical-align: top;
  flex-shrink: 0;
  width: $profile-width;
  font: 1rem $feature-font-family;
  color: $social-iconfont-color;
  // 为了在没有social和friends时也显示一个分割线
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  a {
    transition: color 0.1s ease-in;
  }
  & > *:last-child {
    border-bottom: none;
  }
}

.profile.profile-sticky {
  position: sticky;
  top: $header-height;
}

.profile-avatar {
  display: block;
  border-radius: 1rem;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &.hide-border {
    border: unset;
  }
}

.profile-name {
  font-size: 2rem;
  line-height: 3rem;
  padding: 0.5rem 0 0 0;
  color: #333;
  text-align: center;
}

.profile-signature {
  font-size: 1rem;
  text-align: center;
}

.profile-social {
  position: relative;
  width: 100%;
  text-align: center;
  line-height: 1.7rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .iconfont-archer {
    position: relative;
  }
  @include social($scaleSize: 0.5, $spanMargin: 0.4rem);
}

.wechat,
.qq {
  cursor: pointer;
  &:hover {
    .profile-qr {
      opacity: 1;
      visibility: visible;
    }
  }
}

.profile-qr {
  visibility: hidden;
  position: absolute;
  left: 0;
  bottom: 2.5rem;
  border: 3px solid #fff;
  border-radius: 3px;
  z-index: 99;
  background-color: #fff;
  width: 7rem;
  padding: 0.5rem;
  opacity: 0;
  transition: all 0.1s ease-in 0s;
}

.friends {
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: left;
  div {
    font-size: 1.2rem;
    text-align: left;
    font-weight: bold;
  }
  a {
    margin-right: 0.6rem;
    color: $social-iconfont-color;
    &:hover {
      color: $feature-color;
    }
  }
}

.profile-link-item {
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  a {
    font-size: 1.2rem;
    font-weight: bold;
    color: $social-iconfont-color;
    &:hover {
      color: $feature-color;
    }
  }
}

.profile-item-icon {
  margin-right: 0.4rem;
  width: 1.2rem !important;
}

.about-me {
}

.search-me {
}
