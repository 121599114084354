// ========== code style ========== //
.abstract-content,
.article-entry {
  // ========== toggle ========== //
  pre,
  .highlight {
    margin: 0.8rem 0;
    padding: 0.7rem 0.7rem;
    overflow: auto;
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
  .highlight .gutter pre,
  .highlight .gutter pre .line,
  .gist .gist-file .gist-data .line-numbers {
    color: #666;
  }
  pre,
  code {
    font-family: 'Source Code Pro', Consolas, Menlo, Monaco, 'Courier New',
      monospace;
    font-weight: 400;
  }
  code {
    background: #eee;
    padding: 2px 0.4rem;
    border-radius: 4px;
    margin: 0 0.2rem;
    border: none;
    transition: $transition-animation;
  }
  pre code {
    background: none;
    text-shadow: none;
    padding: 0;
    color: #657b83;
  }
  .highlight {
    border-radius: 4px;
  }
  .highlight pre {
    border: none;
    margin: 0;
    padding: 0;
  }
  .highlight table {
    margin: 0;
    width: auto;
  }
  .highlight td {
    border: none;
    padding: 0;
  }
  .highlight figcaption {
    color: highlight-comment;
    line-height: 1em;
    margin-bottom: 1em;
  }
  .highlight figcaption:before,
  .highlight figcaption:after {
    content: '';
    display: table;
  }
  .highlight figcaption:after {
    clear: both;
  }
  .highlight figcaption a {
    float: right;
  }
  .highlight .gutter pre {
    text-align: right;
    padding-right: 20px;
  }
  .highlight .gutter pre .line {
    text-shadow: none;
  }
  .highlight .line {
    min-height: 19px;
  }

  // ========== code color ========== //
  .highlight,
  pre {
    color: map-get($theme-colors, 'mono-1');
    background: map-get($theme-colors, 'base');
  }
  .highlight {
    pre .comment,
    pre .quote {
      color: map-get($theme-colors, 'mono-3');
      font-style: italic;
    }

    pre .doctag,
    pre .keyword,
    pre .formula {
      color: map-get($theme-colors, 'hue-3');
    }

    pre .section,
    pre .name,
    pre .selector-tag,
    pre .deletion,
    pre .subst {
      color: map-get($theme-colors, 'hue-5');
    }

    pre .literal {
      color: map-get($theme-colors, 'hue-1');
    }

    pre .string,
    pre .regexp,
    pre .addition,
    pre .attribute,
    pre .meta-string {
      color: map-get($theme-colors, 'hue-4');
    }

    pre .built_in,
    pre .class pre .title {
      color: map-get($theme-colors, 'hue-6-2');
    }

    pre .attr,
    pre .variable,
    pre .template-variable,
    pre .type,
    pre .selector-class,
    pre .selector-attr,
    pre .selector-pseudo,
    pre .number {
      color: map-get($theme-colors, 'hue-6');
    }

    pre .symbol,
    pre .bullet,
    pre .link,
    pre .meta,
    pre .selector-id,
    pre .title {
      color: map-get($theme-colors, 'hue-2');
    }

    pre .emphasis {
      font-style: italic;
    }

    pre .strong {
      font-weight: bold;
    }

    pre .link {
      text-decoration: underline;
    }
  }

  // ========== gist ========== //
  .gist {
    margin: 0 -20px;
    border-style: solid;
    border-color: #ddd;
    border-width: 1px 0;
    background: #272822;
    padding: 15px 20px 15px 0;
  }
  .gist .gist-file {
    border: none;
    font-family: 'Source Code Pro', Consolas, Monaco, Menlo, Consolas, monospace;
    margin: 0;
  }
  .gist .gist-file .gist-data {
    background: none;
    border: none;
  }
  .gist .gist-file .gist-data .line-numbers {
    background: none;
    border: none;
    padding: 0 20px 0 0;
  }
  .gist .gist-file .gist-data .line-data {
    padding: 0 !important;
  }
  .gist .gist-file .highlight {
    margin: 0;
    padding: 0;
    border: none;
  }
  .gist .gist-file .gist-meta {
    background: #272822;
    color: highlight-comment;
    font:
      0.85em 'Helvetica Neue',
      Helvetica,
      Arial,
      sans-serif;
    text-shadow: 0 0;
    padding: 0;
    margin-top: 1em;
    margin-left: 20px;
  }
  .gist .gist-file .gist-meta a {
    color: #258fb8;
    font-weight: normal;
  }
  .gist .gist-file .gist-meta a:hover {
    text-decoration: underline;
  }
}
